import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import react from 'react';
import withNeon, { fx } from "react-neon";
import Tile from "../../components/Tile.js";
const effect = new fx.Snow({ fullscreen: true });
const FullscreenSnow = withNeon(Tile, effect);
export const frontmatter = {
  title: 'Snow',
  author: 'Chris Neale',
  draft: false,
  date: '2018-10-03T23:40:00.00Z',
  tags: ['fullscreen']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>


<MDXTag name="h3" components={components}>{`Full Screen Example`}</MDXTag>
<MDXTag name="p" components={components}>{`React Neon fullscreen effects by passing an option of 'fullscreen' that's set to true. This will attach the effect to the top left of the screen and set it's dimensions to match the page.`}</MDXTag>
<FullscreenSnow />
<MDXTag name="p" components={components}>{`In the example code below an image Tile component has been used for the ResizeObserver to listen to, but the effect could be attached to a real component in a proper app or website.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Example Code`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript">{"  "}<span className="token keyword">import</span> withNeon<span className="token punctuation">,</span> <span className="token punctuation">{"{"}</span> fx <span className="token punctuation">{"}"}</span> <span className="token keyword">from</span> <span className="token string">"react-neon"</span><span className="token punctuation">;</span>{"\n"}{"  "}<span className="token keyword">import</span> Tile <span className="token keyword">from</span> <span className="token string">"../components/Tile.js"</span><span className="token punctuation">;</span>{"\n"}{"  "}<span className="token keyword">const</span> effect <span className="token operator">=</span> <span className="token keyword">new</span> <span className="token class-name">fx<span className="token punctuation">.</span>Snow</span><span className="token punctuation">(</span><span className="token punctuation">{"{"}</span> fullscreen<span className="token punctuation">:</span> <span className="token boolean">true</span> <span className="token punctuation">{"}"}</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}{"  "}<span className="token keyword">const</span> FullscreenSnow <span className="token operator">=</span> <span className="token function">withNeon</span><span className="token punctuation">(</span>Tile<span className="token punctuation">,</span> effect<span className="token punctuation">)</span><span className="token punctuation">;</span></code></pre></div>
</MDXTag>

export const _frontmatter = {};

  